<template>
  <div style="display: flex; flex-direction: column; justify-content: space-between; height: 100%">
    <a-alert
      style="margin-bottom: 20px"
      v-if="!rootState.userInfo.hasCompany"
      banner
    >
      <span slot="message">
        Welkom bij Import4You {{ rootState.userInfo.name }}! Voordat je een boeking kan aanmaken,
        dien je de registratie te voltooien. Ga naar <router-link :to="{ name: 'CreateCompany'}">Voltooi registratie</router-link>

      </span>
    </a-alert>
    <div>
      <div class="dashboard__information">
        <div class="dashboard__information--item">
          <img
            class="itemImage"
            :src="waiting"
            alt="">
          <div>
            <div class="itemNumber">
              <animated-number
                :value="dashboardMain.dashboardInformation.transport[1] || 0"
                :formatValue="formatValue"
                :duration="800"
              />
            </div>
            <p class="itemText">
              LUCHTVRACHT BOEKINGEN
            </p>
            <a class="itemLink" :href="airFreightManualUrl">
              Handleiding luchtvracht
            </a>
          </div>
        </div>
        <div class="dashboard__information--item">
          <img
            class="itemImage"
            :src="all"
            alt="">
          <div>
            <div class="itemNumber">
              <animated-number
                :value="dashboardMain.dashboardInformation.transport[2] || 0"
                :formatValue="formatValue"
                :duration="800"
              />
            </div>
            <p class="itemText">
              ZEEVRACHT BOEKINGEN
            </p>
            <a class="itemLink" :href="seaFreightManualUrl">
              Handleiding zeevracht
            </a>
          </div>
        </div>
        <div class="dashboard__information--item">
          <img
            class="itemImage"
            :src="total"
            alt="">
          <div>
            <div class="itemNumber">
              <animated-number
                :value="dashboardMain.dashboardInformation.bookingStatus[1] || 0"
                :formatValue="formatValue"
                :duration="800"
              />
            </div>
            <p class="itemText">
              BOEKINGEN IN AFWACHTING
            </p>
          </div>
        </div>
      </div>
      <div class="dashboard__information">
        <div class="dashboard__information--item">
          <img
            class="itemImage"
            :src="pin"
            alt="">
          <div>
            <div class="itemNumber">
              <animated-number
                :value="dashboardMain.dashboardInformation.bookingStatus[6] || 0"
                :formatValue="formatValue"
                :duration="800"
              />
            </div>
            <p class="itemText">
              AFGERONDE BOEKINGEN
            </p>
          </div>
        </div>
        <div class="dashboard__information--item">
          <img
            class="itemImage"
            :src="boxes"
            alt="">
          <div>
            <div class="itemNumber">
              <animated-number
                :value="dashboardMain.dashboardInformation.total || 0"
                :formatValue="formatValue"
                :duration="800"
              />
            </div>
            <p class="itemText">
              TOTAAL BOEKINGEN
            </p>
          </div>
        </div>
      </div>
      <DeliveryInfo />
    </div>
    <div style="justify-content: center; display: flex;">
      <a-button
        type="primary"
        size="large"
        class="btn_create_booking"
        :disabled="!rootState.userInfo.hasCompany"
        @click="$router.push({name: 'NewBooking'})"
      >
        Boeking aanmaken
      </a-button>
    </div>
<!--    <NotificationModal-->
<!--      v-if="isShowModal === 'true'"-->
<!--      @close="isShowModal = 'false'"-->
<!--    />-->
  </div>
</template>

<script>

import waiting from '@/assets/dashboard/waiting.svg'
import all from '@/assets/dashboard/all.svg'
import total from '@/assets/dashboard/total.svg'
import pin from '@/assets/dashboard/pin.svg'
import boxes from '@/assets/dashboard/boxes.svg'
import {mapActions, mapState} from "vuex";

import AnimatedNumber from "animated-number-vue";
import NotificationModal from "@/components/dashboard/NotificationModal";
import DeliveryInfo from "./DeliveryInfo.vue"

export default {
  name: "DashboardInformation",
  components: {
    AnimatedNumber,
    NotificationModal,
    DeliveryInfo
  },
  data() {
    return {
      waiting,
      all,
      pin,
      boxes,
      total,
      // get isShowModal() {
      //   return localStorage.getItem('isNotificationShow');
      // },
      // set isShowModal(value) {
      //   localStorage.setItem('isNotificationShow', value);
      // },
    }
  },
  created() {
    this.getDashboardInformation()
  },
  computed: {
    ...mapState({
      dashboardMain: state => state.dashboardMain,
      rootState: state => state,
    }),
    airFreightManualUrl() {
      return `${process.env.VUE_APP_API_URL}/freight/air/manual`;
    },
    seaFreightManualUrl() {
      return `${process.env.VUE_APP_API_URL}/freight/sea/manual`;
    },
  },
  methods: {
    ...mapActions([
      'getDashboardInformation'
    ]),
    formatValue(value) {
      return Number(value).toFixed(0)
    }
  },

}
</script>

<style lang="scss">
.dashboard__information {
  display: flex;
  justify-content: center;

}

.dashboard__information--item {
  display: flex;
  align-items: center;
  background: white;
  max-width: 33.333333%;
  padding: 20px;
  margin: 16px;
}

.itemImage {
  width: 100px;
}

.itemText {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.7;
  color: #263238;
  font-weight: 500;
  text-transform: uppercase;
  margin-left: 10px;
}

.itemLink {
  margin-left: 10px;
  display: block;
}

.itemNumber {
  font-size: 50px;
  font-weight: 500;
  color: #71797e;
  margin-left: 10px;
  line-height: 1.2;
}
</style>
