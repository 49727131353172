<template>
  <div class="deliveryInfo-block">
    <div class="openBtn">
      <CustomBtn
        type="primary"
        size="large"
        @click="openExtraWindow"
        title="Levertijden"
      >
        <a-icon
          :class="{ open: isOpenWindow, close: !isOpenWindow }"
          type="double-left"
        />
      </CustomBtn>
    </div>
    <div class="description" :class="{ description__open: isOpenWindow }">
      <div>
        <p class="description__main-title">
          Deze levertijden zijn vanaf het vertrek van de boot/het vliegtuig
        </p>
      </div>
      <div
        class="description__wrapper"
        v-for="{ id, title, subTitle } in descriptionData"
        :key="id"
      >
        <p class="description__title">{{ title }}</p>
        <p class="description__sub-title">{{ subTitle }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import CustomBtn from "@/components/customComponents/CustomBtn.vue";

export default {
  name: "DeliveryInfo",
  data() {
    return {
      descriptionData: [
        { id: 1, title: "Shanghai luchtvracht", subTitle: "7-14 dagen" },
        { id: 2, title: "Shenzhen luchtvracht", subTitle: "7-14 dagen" },
        { id: 3, title: "Shanghai zeevracht", subTitle: "6-7 weken" },
        { id: 4, title: "Shenzhen zeevracht", subTitle: "5-6 weken" },
        { id: 5, title: "Ningbo zeevracht", subTitle: "6-7 weken" },
      ],
      isOpenWindow: false,
    };
  },
  components: {
    CustomBtn,
  },
  methods: {
    openExtraWindow() {
      this.isOpenWindow = !this.isOpenWindow;
    },
  },
};
</script>

<style lang="scss" scoped>
.deliveryInfo-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
::v-deep .openBtn {
  margin-bottom: 15px;
}
.description {
  position: relative;
  width: 300px;
  padding: 10px 20px;
  color: #fff;
  font-weight: 500;
  line-height: 1.7;
  border: 1px solid #ffa300;
  border-radius: 10px;
  z-index: 111;
  transform: scale(1.5);
  z-index: -1;
  opacity: 0;
  transition: all 350ms ease-in;
  &::before {
    position: absolute;
    content: "";
    top: -8px;
    left: 146px;
    display: block;
    width: 19px;
    height: 1px;
    background-color: #ffa300;
    transform: rotate(45deg);
  }
  &::after {
    position: absolute;
    content: "";
    top: -8px;
    right: 146px;
    display: block;
    width: 19px;
    height: 1px;
    background-color: #ffa300;
    transform: rotate(135deg);
  }
  &__open {
    transform: scale(1);
    z-index: 0;
    opacity: 1;
    pointer-events: none;
    // transform: translateY(0) scale(1);
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    padding-left: 20%;
  }
  &__wrapper:not(:last-child) {
    margin-bottom: 10px;
  }
  &__main-title {
    text-align: center;
    margin-bottom: 20px;

    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: #263238;
  }
  &__main-title::before {
    position: absolute;
    content: "";
    top: -1px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 25px;
    height: 1px;
    background-color: #ffffff;
  }
  &__title {
    margin: 0;

    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    color: #71797e;
  }
  &__sub-title {
    margin: 0;

    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #ffa300;
  }
}
::v-deep .close.anticon-double-left,
::v-deep .open.anticon-double-left {
  transition: all 350ms ease-in;
}
::v-deep .close.anticon-double-left {
  transform: rotate(270deg);
}
::v-deep .open.anticon-double-left {
  transform: rotate(90deg);
}
</style>
